import React from 'react';
import ReactDOM from 'react-dom';

import './custom.scss'
import '@fortawesome/fontawesome-free/css/all.min.css';

import App from './App';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);